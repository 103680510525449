@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

a {
  cursor: unset;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  height: 100vh;
}
html {
  height: -webkit-fill-available;
}

#root {
  width: 100%;
  height: 100%;
}

:root {
  --bg-home: #dddedf;
  --bg-slider: #001a27;
  --text-secondary: #989c9e;
  --text-primary: #ffffff;
  --text-description: #c1c8cc;
  --mobile-breakpoint: 799;
}

.App {
  font-family: sans-serif;
  text-align: center;

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  background: #001a27;
  background: var(--bg-slider);
}

.slider__wrapper.home {
  background: #dddedf;
  background: var(--bg-home);
}

.slider__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__bg img {
  position: absolute;
  z-index: -1;
  height: 80vh;
  -webkit-filter: drop-shadow(3px 1px 0px #0000008c);
          filter: drop-shadow(3px 1px 0px #0000008c);
  transition: 0.2s;
  opacity: 0;
}

.slider__bg img.visible {
  opacity: 1;
}

body {
  position: fixed;
  padding: 0;
  margin: 0;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: "Amiri", serif;
  color: white;
  background: black;
  background: #dddedf;
  background: var(--bg-home);
  cursor: url("https://github.com/chenglou/react-motion/raw/master/demos/demo8-draggable-list/cursor.png")
      39 39,
    auto;
}

.model__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.main__wrapper {
  position: absolute;
  z-index: 100;
  -webkit-transform: translateX(10vw);
          transform: translateX(10vw);
  display: flex;
  flex-direction: column;
}

.main__wrapper.hidden {
  opacity: 0;
}

.main__wrapper img {
  width: 11rem;
}

.main__wrapper p {
  text-align: left;
  color: #989c9e;
  color: var(--text-secondary);
  font-size: 1.5rem;
}

.nav__vertical {
  position: fixed;
  height: 100vh;
  right: 0;
  display: flex;
  align-items: center;
  /* padding-right: 30px; */
  z-index: 1020;
}

.nav__horizontal {
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  z-index: 102;
}

.nav__horizontal ul {
  list-style: none;
  color: #989c9e;
  color: var(--text-secondary);
  font-size: 1.5rem;
  display: flex;
}

.nav__horizontal ul li {
  position: relative;
  padding-left: 12px;
}

.nav__horizontal ul li:not(:last-child) {
  padding-right: 12px;
  border-right: #989c9e solid 1px;
  border-right: var(--text-secondary) solid 1px;
}

.nav__horizontal ul li p {
  margin: 0;
  font-size: 1.2rem;
}

.nav__vertical ul {
  position: relative;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 50%;
  padding-right: 30px; /*new*/
}

.nav__vertical ul li {
  padding: 0px 10px;
  position: relative;
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.nav__vertical ul li:not(:last-child) {
  margin-bottom: 40px;
}

.nav__vertical ul li::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  bottom: 0;
  /* right: 0; */
  left: 0;
  background-color: #989c9e;
  background-color: var(--text-secondary);
}

.nav__vertical ul li p {
  color: #989c9e;
  color: var(--text-secondary);
  font-size: 1.2rem;

  margin: 0;
}

.mySwiper__slide {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
}

.aboutSwiper__slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.mh-50 {
  max-height: 100vh;
}
.oh {
  overflow: hidden;
}

.mySwiper__wrapper::after {
  content: "";
  background: #001a2773;
  top: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  margin: auto;
  z-index: -1;
  -webkit-filter: blur(20px);
          filter: blur(20px);
}

.mySwiper__wrapper {
  width: 35vw;
  text-align: left;
  -webkit-transform: translate(300px, 80px);
          transform: translate(300px, 80px);
}

.mySwiper__wrapper ul li {
  color: #c1c8cc;
  color: var(--text-description);
  line-height: 1.5;
  padding: 5px 10px;
}

.mySwiper__wrapper h1 {
  color: #ffffff;
  color: var(--text-primary);
  font-size: 1.75rem;
  margin: 13px 0;
}

.mySwiper__wrapper h6 {
  color: #c1c8cc;
  color: var(--text-description);
  margin: 5px 0;
  font-size: 1.5rem;
}

.mySwiper__wrapper p {
  color: #c1c8cc;
  color: var(--text-description);
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
}

.arrow-scroll {
  width: 40px;
  height: 55px;
  position: absolute;
  left: 50%;
  z-index: 102;
  bottom: 0px;
}

.arrow-scroll path {
  stroke: #989c9e;
  stroke: var(--text-secondary);
  fill: transparent;
  stroke-width: 3px;
  animation: seta 2s infinite;
  -webkit-animation: scroll 2s infinite;
}

.arrow-scroll path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
}

.arrow-scroll path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
}

.contacts {
  -webkit-transform: translate(300px, 0);
          transform: translate(300px, 0);
}

.contacts img {
  width: 200px;
}

.contacts ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 50px;
}

.contacts ul li {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.contacts ul li div:first-child {
  width: 150px;
}

.contacts ul li div:last-child {
  text-align: left;
}

.contacts ul li:not(:last-child) {
  margin-bottom: 15px;
}

.contacts ul li div a {
  color: #989c9e;
  color: var(--text-secondary);
  font-size: 1.2rem;
}

.temp {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #dddedf;
  background: var(--bg-home);
  z-index: 100;
  top: 0;
}

.about__wrapper {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100vh;
  transition: opacity 0.1s ease;
  overflow: hidden;
  display: block;
}

.about__wrapper.visible {
  opacity: 1;
  z-index: 1000;
}

.aboutSwiper__slide .half-col {
  width: 50vw;
  height: 100vh;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aboutSwiper__slide .one-third-col {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutSwiper__slide .two-third-col {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
}

.aboutSwiper__slide .two-third-col h1 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.aboutSwiper__slide .two-third-col p {
  font-size: 0.9rem;
}

.aboutSwiper__slide .dark {
  background-color: #001a27;
  background-color: var(--bg-slider);
}

.aboutSwiper__slide .light {
  background-color: #dddedf;
  background-color: var(--bg-home);
}

#aboutUslogo {
  width: 250px;
}

#desktop_screen {
  max-width: 1100px;
  width: 100%;
  padding: 0 50px;
  -webkit-filter: drop-shadow(2px 4px 3px #000);
          filter: drop-shadow(2px 4px 3px #000);
}

#tablet_screen {
  width: 90%;
  -webkit-filter: drop-shadow(2px 4px 3px #000);
          filter: drop-shadow(2px 4px 3px #000);
}

.about__wrapper .title {
  position: fixed;
  top: 0px;
  right: 30px;
  z-index: 1000;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #989c9e;
  color: var(--text-secondary);
}

.full-col {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile_bg h1 {
  color: #dddedf;
  color: var(--bg-home);
  position: absolute;
  top: 20%;
  z-index: 1001;
  text-transform: uppercase;
}

#full_img_bg {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutSwiper__slide .full {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutSwiper__slide .full > h6 {
  font-size: 2.7rem;
  padding: 0 50px;
}

.aboutSwiper__slide .light {
  background-color: #dddedf;
  background-color: var(--bg-home);
}

.aboutSwiper__slide .dark {
  background-color: #001a27;
  background-color: var(--bg-slider);
}

.aboutSwiper__slide .dark > h6 {
  color: #dddedf;
  color: var(--bg-home);
  text-align: center;
  width: 100%;
  max-width: 700px;
}

.aboutSwiper__slide .one-third-col .bg_img,
.aboutSwiper__slide .half-col .bg_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.aboutSwiper__slide .light > h6 {
  color: #001a27;
  color: var(--bg-slider);
  text-align: center;
  width: 100%;
  max-width: 700px;
}

.aboutSwiper__slide .light > h1 {
  color: #001a27;
  color: var(--bg-slider);
  text-align: left;
  width: 80%;
  text-transform: uppercase;
  max-width: 500px;
}

.aboutSwiper__slide .dark > h1 {
  color: #dddedf;
  color: var(--bg-home);
  text-align: left;
  width: 80%;
  text-transform: uppercase;
  max-width: 500px;
}

.aboutSwiper__slide .light > p {
  color: #001a27;
  color: var(--bg-slider);
  text-align: left;
  width: 80%;
  max-width: 500px;
}

.aboutSwiper__slide .dark > p {
  color: #dddedf;
  color: var(--bg-home);
  text-align: left;
  width: 80%;
  max-width: 500px;
}

.nav-dark {
  color: #001a27 !important;
  color: var(--bg-slider) !important;
}

.nav-light {
  color: #dddedf !important;
  color: var(--bg-home) !important;
}

.aboutUs_arrow {
  visibility: visible;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 1000;
}

.aboutUs_arrow.hide {
  visibility: hidden;
}

form .textfield {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  border-bottom: 1px solid #dddedf;
  border-bottom: 1px solid var(--bg-home);
  padding: 0;
  padding-bottom: 7px;
  padding-left: 5px;
  margin-left: 0;
}

form .textfield input {
  background: transparent;
  font-size: 1.2rem;
  flex: 1 1;
  color: #ffffff;
  color: var(--text-primary);
  border: none;
}

form .textfield button svg:hover {
  -webkit-animation: arrow-jump 0.35s ease-in alternate infinite;
          animation: arrow-jump 0.35s ease-in alternate infinite;
}

form .textfield button {
  background: transparent;
  border: none;
}

textarea:focus,
input:focus {
  outline: none;
}

.mySwiper__wrapper.dark::after {
  background: #001a27ba;
}

form .textfield input::-webkit-input-placeholder {
  color: #ffffff;
  color: var(--text-primary);
  opacity: 0.6;
  font-size: 16px;
}

form .textfield input:-ms-input-placeholder {
  color: #ffffff;
  color: var(--text-primary);
  opacity: 0.6;
  font-size: 16px;
}

form .textfield input::placeholder {
  color: #ffffff;
  color: var(--text-primary);
  opacity: 0.6;
  font-size: 16px;
}

form .textfield svg {
  font-size: 2.8em;
  color: #ffffff;
  color: var(--text-primary);
  cursor: pointer;
  padding-left: 12px;
  /* transition: 0.5s; */
}

.form__error_message {
  color: #d15454 !important;
  font-size: 14px !important;
}

.footer__newsletter:hover .textfield svg {
  stroke: var(--white) !important;
}

.footer__newsletter .textfield svg:hover {
  -webkit-animation: arrow-jump 0.35s ease-in alternate infinite;
          animation: arrow-jump 0.35s ease-in alternate infinite;
}

.loader--style2 .loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

.loader svg {
  cursor: default !important;
}

.loader svg path,
.loader svg rect {
  fill: #fff;
}

#loader-1 {
  enable-background: new 0 0 50 50;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes scroll {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1120px) {
  .mySwiper__wrapper {
    -webkit-transform: translate(200px, 80px);
            transform: translate(200px, 80px);
  }
}

@media screen and (max-width: 850px) {
  .mySwiper__wrapper {
    -webkit-transform: translate(100px, 80px);
            transform: translate(100px, 80px);
    width: 40vw;
  }
}

@media screen and (max-width: 799px) {
  .main__wrapper {
    -webkit-transform: unset;
            transform: unset;
    padding: 0 40px;
    position: absolute;
    bottom: 30%;
  }

  .mySwiper__slide {
    padding-bottom: 100px;
  }

  .main__wrapper img {
    width: 150px;
  }

  .main__wrapper p {
    font-size: 1.3rem;
    font-weight: 400;
    margin: 7px 0;
  }

  .nav__vertical {
    bottom: 20px;
    height: unset;
    width: 100%;
    padding-right: 0;
  }

  .nav__vertical ul {
    margin: 0;
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .nav__vertical ul li {
    -webkit-writing-mode: unset;
        -ms-writing-mode: unset;
            writing-mode: unset;
    -webkit-transform: unset;
            transform: unset;
  }

  .nav__vertical ul li p {
    font-size: 1rem;
  }

  .nav__vertical ul li::after {
    position: absolute;
    content: "";
    width: calc(100% - 20px);
    height: 2px;
    bottom: -5px;
    left: 0;
    margin: auto;
    right: 0;
    background-color: #989c9e;
    background-color: var(--text-secondary);
  }

  .slider__bg img {
    height: 250px;
    top: 60px;
  }

  .nav__horizontal,
  .nav__vertical {
    position: fixed;
  }

  .nav__vertical ul li {
    margin-bottom: unset;
  }

  .nav__vertical ul li:not(:last-child) {
    margin-bottom: unset;
  }

  .arrow-scroll {
    left: 0;
    right: 0;
    margin: auto;
    bottom: 10px;
    -webkit-transform: scale(0.8) rotate(-180deg);
            transform: scale(0.8) rotate(-180deg);
    position: fixed;
  }

  .mySwiper__wrapper {
    width: 100vw;
    padding: 0 30px;
    -webkit-transform: translate(0px, 35%);
            transform: translate(0px, 35%);
    max-height: 86vh;
    overflow: auto;
    align-self: center;
  }

  .mySwiper__wrapper.audience {
    -webkit-transform: translate(0px, 10%);
            transform: translate(0px, 10%);
  }

  .contacts img {
    width: 120px;
  }

  .contacts h6 {
    font-size: 16px;
  }

  .contacts ul li {
    display: flex;
    flex-direction: column;
  }

  .contacts ul {
    margin-top: 30px;
  }

  .contacts ul li div:first-child {
    width: unset;
  }

  .contacts ul li div:first-child p {
    font-size: 0.8rem;
  }

  .mySwiper__wrapper.contacts {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .swiper-slide-active.mySwiper__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .mySwiper__wrapper::after {
    content: unset;
  }

  .aboutSwiper__slide {
    display: flex;
    flex-direction: column;
  }

  #aboutUslogo {
    width: 150px;
  }

  .aboutSwiper__slide .full > h6 {
    padding: 0 25px;
    font-size: 2.2rem;
  }
}

@-webkit-keyframes arrow-jump {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}

@keyframes arrow-jump {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}

